<template>
  <div class="bg-[#fff8ee] min-h-screen font-sans">
    <Header ref="header" />
    <div ref="container">
      <slot />
    </div>
    <Footer ref="footer" />
    <SocialShare />
  </div>
</template>

<script setup>
const header = ref(null);
const footer = ref(null);
const container = ref(null);

onMounted(() => {
  const height = header.value.$el.clientHeight + footer.value.$el.clientHeight;
  container.value.style.minHeight = 'calc(100vh - ' + height + 'px)';
})

// Google Tag Manager
useHead({
  script: [{ children: "(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-N2Z9SVK')" }],
});

// Google Tag Manager (noscript)
useHead({
  noscript: [
    {
      children: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-N2Z9SVK" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      body: true
    }
  ]
});

const runtimeConfig = useRuntimeConfig();
//SEO meta
useSeoMeta({
  title: 'Happy Box in regalo!',
  ogTitle: 'Happy Box in regalo!',
  ogDescription: 'La confezione ricca di prodotti e servizi per le mamme e i papà in dolce attesa.',
  ogImage: `${runtimeConfig.public.url}/images/happy_box.png`,
  ogType: 'website'
})
</script>